"use client";

import * as React from "react";
import { createPortal } from "react-dom";
import { cn } from "@/app/lib/utils";

interface TooltipState {
  content: string;
  x: number;
  y: number;
  visible: boolean;
  side: "top" | "right" | "bottom" | "left";
}

/**
 * A global tooltip provider that works with data attributes
 *
 * Usage:
 * 1. Add the SimpleTooltipProvider to your app (ideally at the root)
 * 2. Add data-tooltip attribute to any element:
 *    <button data-tooltip="Click me!">Hover me</button>
 *
 * Optional attributes:
 * - data-tooltip-side="top|right|bottom|left" (default: "top")
 * - data-tooltip-delay="300" (in ms, default: 0)
 */
export function SimpleTooltipProvider() {
  const [tooltip, setTooltip] = React.useState<TooltipState>({
    content: "",
    x: 0,
    y: 0,
    visible: false,
    side: "top",
  });

  React.useEffect(() => {
    const handleMouseOver = (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const tooltipContent = target.getAttribute("data-tooltip");

      if (!tooltipContent) return;

      const rect = target.getBoundingClientRect();
      const side =
        (target.getAttribute("data-tooltip-side") as
          | "top"
          | "right"
          | "bottom"
          | "left") || "top";
      const delay = parseInt(
        target.getAttribute("data-tooltip-delay") || "0",
        10
      );

      let x = 0;
      let y = 0;

      // Position the tooltip based on the side
      switch (side) {
        case "top":
          x = rect.left + rect.width / 2;
          y = rect.top;
          break;
        case "right":
          x = rect.right;
          y = rect.top + rect.height / 2;
          break;
        case "bottom":
          x = rect.left + rect.width / 2;
          y = rect.bottom;
          break;
        case "left":
          x = rect.left;
          y = rect.top + rect.height / 2;
          break;
      }

      const timer = setTimeout(() => {
        setTooltip({
          content: tooltipContent,
          x,
          y,
          visible: true,
          side,
        });
      }, delay);

      return () => clearTimeout(timer);
    };

    const handleMouseOut = () => {
      setTooltip((prev) => ({ ...prev, visible: false }));
    };

    document.addEventListener("mouseover", handleMouseOver);
    document.addEventListener("mouseout", handleMouseOut);

    return () => {
      document.removeEventListener("mouseover", handleMouseOver);
      document.removeEventListener("mouseout", handleMouseOut);
    };
  }, []);

  if (!tooltip.visible) return null;

  return createPortal(
    <div
      className={cn(
        "absolute z-50 px-3 py-1.5 text-sm rounded-md border bg-white dark:bg-gray-800 text-black dark:text-white shadow-md animate-in fade-in-0 zoom-in-95",
        {
          "transform -translate-x-1/2 -translate-y-full mb-2":
            tooltip.side === "top",
          "transform translate-y-1/2 ml-2": tooltip.side === "right",
          "transform -translate-x-1/2 mt-2": tooltip.side === "bottom",
          "transform -translate-x-full translate-y-1/2 mr-2":
            tooltip.side === "left",
        }
      )}
      style={{
        left: `${tooltip.x}px`,
        top: `${tooltip.y}px`,
      }}
      role="tooltip"
    >
      {tooltip.content}
    </div>,
    document.body
  );
}

/**
 * A directive-like tooltip component that can be used with any element
 *
 * Usage:
 * <TooltipDirective content="This is a tooltip">
 *   <button>Hover me</button>
 * </TooltipDirective>
 */
interface TooltipDirectiveProps {
  content: string;
  side?: "top" | "right" | "bottom" | "left";
  delay?: number;
  children: React.ReactElement;
}

export function TooltipDirective({
  content,
  side = "top",
  delay = 0,
  children,
}: TooltipDirectiveProps) {
  return React.cloneElement(children, {
    "data-tooltip": content,
    "data-tooltip-side": side,
    "data-tooltip-delay": delay,
  });
}
