import { useEffect } from 'react';
import { useAppSelector } from '../store/hooks';
import { selectOperatingSystem } from '../store/slices/appSelectors';

interface UseGlobalShortcutsProps {
    onFeedbackOpen: () => void;
    onCreateTaskOpen?: () => void;
}

export function useGlobalShortcuts({ onFeedbackOpen, onCreateTaskOpen }: UseGlobalShortcutsProps) {
    const operatingSystem = useAppSelector(selectOperatingSystem);

    useEffect(() => {
        let isCommandKPressed = false;

        const handleKeyDown = (event: KeyboardEvent) => {
            // Check for Command/Control + K based on OS
            const isMac = operatingSystem === 'mac';
            const isModifierKeyPressed = isMac ? event.metaKey : event.ctrlKey;

            if (isModifierKeyPressed && event.key.toLowerCase() === 'k') {
                event.preventDefault();
                isCommandKPressed = true;
                return;
            }

            // If Command/Control + K was pressed and F is pressed
            if (isCommandKPressed && event.key.toLowerCase() === 'f') {
                event.preventDefault();
                onFeedbackOpen();
            }

            // Check for Alt+T / Option+T to open Create Task dialog
            // For macOS, we need to check event.code since Option+T creates a special character
            const isTKeyPressed = isMac
                ? (event.altKey && event.code === 'KeyT')
                : (event.altKey && event.key.toLowerCase() === 't');

            if (isTKeyPressed) {
                event.preventDefault();
                if (onCreateTaskOpen) {
                    onCreateTaskOpen();
                }
            }
        };

        const handleKeyUp = (event: KeyboardEvent) => {
            // Reset the flag when Command/Control or K is released
            const isMac = operatingSystem === 'mac';
            if (event.key.toLowerCase() === 'k' ||
                (isMac ? event.metaKey : event.ctrlKey)) {
                isCommandKPressed = false;
            }
        };

        document.addEventListener('keydown', handleKeyDown);
        document.addEventListener('keyup', handleKeyUp);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
            document.removeEventListener('keyup', handleKeyUp);
        };
    }, [onFeedbackOpen, onCreateTaskOpen, operatingSystem]);
} 