import(/* webpackMode: "eager", webpackExports: ["AppInitializer"] */ "/workspace/app/components/AppInitializer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/app/components/DismissableToaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/workspace/app/components/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SimpleTooltipProvider"] */ "/workspace/app/components/ui/simple-tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/workspace/app/lib/providers.tsx");
;
import(/* webpackMode: "eager" */ "/workspace/app/styles/editor.css");
