"use client";

import { Provider } from "react-redux";
import { store } from "./store/store";
import { AuthProvider } from "../context/auth-context";
import { TaskDialogProvider } from "../context/task-dialog-context";
import { TooltipProvider } from "@/app/components/ui/tooltip";

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <Provider store={store}>
      <AuthProvider>
        <TaskDialogProvider>
          <TooltipProvider>
            {children}
          </TooltipProvider>
        </TaskDialogProvider>
      </AuthProvider>
    </Provider>
  );
}
