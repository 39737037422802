"use client";

import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { Fragment, useState } from "react";
import { useAuth } from "../context/auth-context";
import { CompanySelector } from "../lib/ui/CompanySelector";
import { usePathname, useRouter } from "next/navigation";
import { useAppDispatch, useAppSelector } from "../lib/store/hooks";
import {
  selectCompanies,
  selectSelectedCompany,
  selectCurrentPageHasUnsavedChanges,
} from "../lib/store/slices/appSelectors";
import { changeSelectedCompany } from "../lib/store/slices/appSlice";
import { UsersCompany } from "../lib/interfaces";
import FeedbackModal from "./FeedbackModal";
import { useGlobalShortcuts } from "../lib/hooks/useGlobalShortcuts";
import { useTaskDialog } from "../context/task-dialog-context";

export default function Header() {
  const { user } = useAuth();
  const companies = useAppSelector(selectCompanies);
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const currentPageHasUnsavedChanges = useAppSelector(selectCurrentPageHasUnsavedChanges);
  const dispatch = useAppDispatch();
  const setSelectedCompany = (company: UsersCompany) =>
    dispatch(changeSelectedCompany(company));
  const pathname = usePathname();
  const router = useRouter();
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const { openCreateDialog } = useTaskDialog();

  // Add global shortcuts
  useGlobalShortcuts({
    onFeedbackOpen: () => setShowFeedbackModal(true),
    onCreateTaskOpen: openCreateDialog,
  });

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();

    // Check if we're in a path that needs confirmation
    if (currentPageHasUnsavedChanges && (pathname?.includes("/check") || pathname?.includes("/projects/new"))) {
      setShowExitConfirmation(true);
    } else {
      router.push("/");
    }
  };

  const handleConfirmExit = () => {
    setShowExitConfirmation(false);
    router.push("/");
  };

  if (!user) return null;

  return (
    <>
      <div className="bg-white shadow-sm fixed top-0 left-0 right-0 z-50">
        <div className="container mx-auto px-2 sm:px-4 py-2 md:py-4">
          <div className="flex flex-row justify-between items-center gap-2 md:gap-4">
            <div className="flex flex-row items-center gap-2 md:gap-4">
              <Link
                href="/"
                onClick={handleLogoClick}
                className="text-xl md:text-2xl font-bold hover:text-blue-600 transition-colors whitespace-nowrap z-50"
              >
                <div className="flex flex-col items-center">
                  <span className="">SOTRO</span>
                  <span className="text-xs">management</span>
                </div>
              </Link>
              <div className="min-w-0">
                <CompanySelector
                  companies={companies ?? []}
                  selectedCompany={selectedCompany}
                  onCompanyChange={setSelectedCompany}
                />
              </div>
            </div>
            <div className="flex items-center gap-2 md:gap-4 whitespace-nowrap">
              <span className="hidden sm:inline text-sm md:text-base truncate max-w-[150px]">
                {user.displayName ?? user.email}
              </span>
              <Menu as="div" className="relative">
                <Menu.Button className="p-1.5 md:p-2 hover:bg-gray-100 rounded-full transition-colors">
                  <Bars3Icon className="h-5 w-5 md:h-6 md:w-6 text-gray-600" />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-48 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            href="/settings"
                            className={`${active ? "bg-gray-100" : ""
                              } block px-4 py-2 text-sm text-gray-700`}
                          >
                            Settings
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => setShowFeedbackModal(true)}
                            className={`${active ? "bg-gray-100" : ""
                              } block w-full text-left px-4 py-2 text-sm text-gray-700`}
                          >
                            Suggest / Complain
                          </button>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            href="/auth/logout"
                            className={`${active ? "bg-gray-100" : ""
                              } block px-4 py-2 text-sm text-red-600`}
                          >
                            Logout
                          </Link>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>

      {/* Exit Confirmation Modal */}
      {showExitConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg max-w-md w-full p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Exit Confirmation
            </h3>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to leave this page? Any unsaved changes will
              be lost.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={() => setShowExitConfirmation(false)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleConfirmExit}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
              >
                Leave Page
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Feedback Modal */}
      <FeedbackModal
        isOpen={showFeedbackModal}
        onClose={() => setShowFeedbackModal(false)}
      />
    </>
  );
}
