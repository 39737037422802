"use client";

import { useEffect, useRef } from "react";
import { useAuth } from "@/app/context/auth-context";
import { useAppDispatch, useAppSelector } from "@/app/lib/store/hooks";
import {
  initializeApp,
  verifyCompanies,
} from "@/app/lib/store/slices/appSlice";
import {
  initializeCustomers,
  cleanupAllCustomers,
} from "@/app/lib/store/slices/customers/customersSlice";
import {
  initializeManufacturingFacilities,
  cleanupManufacturingFacilities,
} from "@/app/lib/store/slices/manufacturingFacilities/manufacturingFacilitiesSlice";
import {
  initializeProjects,
  cleanupAllProjects,
} from "@/app/lib/store/slices/projects/projectsSlice";
import { selectSelectedCompany } from "@/app/lib/store/slices/appSelectors";
import {
  initializeCompanyUsers,
  cleanupCompanyUsers,
} from "@/app/lib/store/slices/companyUsers/companyUsersSlice";
import {
  initializeUsedSerialNumbers,
  cleanupUsedSerialNumbers,
} from "@/app/lib/store/slices/usedSerialNumbers/usedSerialNumbersSlice";
import { cleanupAllProductionStages } from "@/app/lib/store/slices/productionStages/productionStagesSlice";
import {
  initializeReservedSerialNumbers,
  setReservedSerialNumbers,
} from "@/app/lib/store/slices/reservedSerialNumbers/reservedSerialNumbersSlice";
import {
  initializeCompanyInformation,
  cleanupCompanyInformation,
} from "@/app/lib/store/slices/companyInformation/companyInformationSlice";
import { usePathname, useRouter } from "next/navigation";
import { cleanupAllOrders } from "../lib/store/slices/orders/ordersSlice";
import { cleanupAllBatches } from "../lib/store/slices/batches/batchesSlice";
import {
  cleanupAllSuppliers,
  clearSuppliersList,
} from "../lib/store/slices/suppliers/suppliersSlice";
import {
  cleanupAllTasks,
  initializeTasks,
} from "../lib/store/slices/tasks/tasksSlice";
import toast from "react-hot-toast";
import { selectPendingOrProgressTasksStatisticsForCurrentUser } from "../lib/store/slices/tasks/tasksSelector";
import { selectCompanyUsersArray } from "../lib/store/slices/companyUsers/companyUsersSelectors";
import { selectCompanyInformation } from "../lib/store/slices/companyInformation/companyInformationSelectors";

// Static public paths
const PUBLIC_PATHS = ["/auth/login", "/auth/register"];

// Function to check if a path is public
const isPublicPath = (path: string) => {
  // Check static public paths
  if (PUBLIC_PATHS.includes(path)) {
    return true;
  }

  // Check dynamic public paths
  if (path.startsWith("/thumbnail/")) {
    return true;
  }

  return false;
};

export function AppInitializer({ children }: { children: React.ReactNode }) {
  const { user, loading: authLoading } = useAuth();
  const dispatch = useAppDispatch();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const companyUsers = useAppSelector(selectCompanyUsersArray);
  const companyInfo = useAppSelector(selectCompanyInformation);
  const router = useRouter();
  const pathname = usePathname();
  const tasksStats = useAppSelector(
    selectedCompany?.companyId
      ? selectPendingOrProgressTasksStatisticsForCurrentUser(
          selectedCompany.companyId,
          user?.uid || ""
        )
      : () => undefined
  );
  const prevCompanyId = useRef(selectedCompany?.companyId);

  // Add a ref to track if we've shown the notification already
  const notificationShownRef = useRef(false);

  // Handle authentication and redirection
  useEffect(() => {
    // Skip redirection logic for public paths
    if (isPublicPath(pathname)) {
      return;
    }

    // If auth is still loading, show loading state
    if (authLoading) {
      return;
    }

    // If auth is done loading and there's no user, redirect to login
    if (!user) {
      // Save the current path to redirect back after login
      if (pathname !== "/") {
        sessionStorage.setItem("redirectPath", pathname);
      }
      router.push("/auth/login");
      return;
    }

    // If user is authenticated and there's a redirect path, go there
    const redirectPath = sessionStorage.getItem("redirectPath");
    if (redirectPath) {
      sessionStorage.removeItem("redirectPath");
      router.push(redirectPath);
    }
  }, [user, authLoading, router, pathname]);

  // Initialize app and verify companies when user changes
  useEffect(() => {
    if (user) {
      dispatch(initializeApp(user));
      dispatch(verifyCompanies(user.uid));
    }
  }, [user, dispatch]);

  // Show task notifications when tasks are loaded
  useEffect(() => {
    if (tasksStats && tasksStats.total > 0 && !notificationShownRef.current) {
      console.log("tasksStats", tasksStats);
      // Set the ref to true to prevent showing again
      notificationShownRef.current = true;

      // Create a more structured toast with visual indicators
      toast(
        <div className="flex items-start space-x-3">
          <div className="text-xl mt-0.5">📋</div>
          <div>
            <p className="font-medium">
              You have <strong>{tasksStats.total}</strong>{" "}
              {tasksStats.total === 1 ? "task" : "tasks"}
            </p>
            <div className="text-sm mt-1 space-y-1">
              {tasksStats.overdue > 0 && (
                <div className="flex items-center">
                  <span className="w-2 h-2 rounded-full bg-red-500 mr-2"></span>
                  <span>
                    <strong>{tasksStats.overdue}</strong> overdue
                  </span>
                </div>
              )}
              {tasksStats.dueIn1Day > 0 && (
                <div className="flex items-center">
                  <span className="w-2 h-2 rounded-full bg-orange-500 mr-2"></span>
                  <span>
                    <strong>{tasksStats.dueIn1Day}</strong> due within 24 hours
                  </span>
                </div>
              )}
              {tasksStats.dueIn2Days > 0 && (
                <div className="flex items-center">
                  <span className="w-2 h-2 rounded-full bg-yellow-500 mr-2"></span>
                  <span>
                    <strong>{tasksStats.dueIn2Days}</strong> due within 2 days
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>,
        {
          duration: 5000,
          style: {
            padding: "12px",
            maxWidth: "380px",
          },
        }
      );
    }
  }, [tasksStats]);

  // Initialize data subscriptions when selected company changes
  useEffect(() => {
    if (prevCompanyId.current !== selectedCompany?.companyId) {
      reinitFirstData();
    }

    if (selectedCompany?.companyId) {
      // Only initialize company users and info if they're not already initialized
      if (!companyUsers || companyUsers.length === 0) {
        dispatch(initializeCompanyUsers());
      }
      if (!companyInfo) {
        dispatch(initializeCompanyInformation());
      }
    } else {
      cleanupAllData();
    }
    prevCompanyId.current = selectedCompany?.companyId;
  }, [selectedCompany?.companyId, dispatch]);

  // Initialize remaining data once company users and info are available
  useEffect(() => {
    if (
      selectedCompany?.companyId &&
      companyUsers &&
      companyUsers.length > 0 &&
      companyInfo
    ) {
      // Only initialize if not already initialized
      dispatch(initializeProjects());
      dispatch(initializeCustomers());
      dispatch(initializeManufacturingFacilities());
      dispatch(initializeUsedSerialNumbers());
      dispatch(initializeReservedSerialNumbers());
      dispatch(initializeTasks());
    }
  }, [selectedCompany?.companyId, companyUsers, companyInfo, dispatch]);

  const reinitFirstData = async () => {
    await cleanupAllData();
    initFirstData();
  };

  const initFirstData = () => {
    dispatch(initializeCompanyUsers());
    dispatch(initializeCompanyInformation());
  };

  const cleanupAllData = async () => {
    await dispatch(cleanupAllProjects()).unwrap();
    await dispatch(cleanupAllCustomers()).unwrap();
    await dispatch(cleanupManufacturingFacilities()).unwrap();
    await dispatch(cleanupAllProductionStages()).unwrap();
    await dispatch(cleanupUsedSerialNumbers()).unwrap();
    await dispatch(cleanupCompanyUsers()).unwrap();
    await dispatch(setReservedSerialNumbers([]));
    await dispatch(cleanupCompanyInformation()).unwrap();
    await dispatch(cleanupAllOrders()).unwrap();
    await dispatch(cleanupAllBatches()).unwrap();
    await dispatch(cleanupAllSuppliers()).unwrap();
    await dispatch(clearSuppliersList());
    await dispatch(cleanupAllTasks()).unwrap();
  };

  // Show loading state only for protected routes while auth is initializing
  if (authLoading && !isPublicPath(pathname)) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
          <p className="mt-2 text-gray-600">Initializing authentication...</p>
        </div>
      </div>
    );
  }

  return <>{children}</>;
}
