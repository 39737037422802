"use client";

import React from "react";
import { toast, Toaster, ToastBar } from "react-hot-toast";

interface DismissableToasterProps {
  position?:
    | "top-left"
    | "top-right"
    | "top-center"
    | "bottom-left"
    | "bottom-right"
    | "bottom-center";
}

export const DismissableToaster: React.FC<DismissableToasterProps> = ({
  position = "top-right",
}) => {
  return (
    <Toaster position={position}>
      {(t) => (
        <div onClick={() => toast.dismiss(t.id)} style={{ cursor: "pointer" }}>
          <ToastBar toast={t} />
        </div>
      )}
    </Toaster>
  );
};

// Add click handler to dismiss toasts
if (typeof window !== "undefined") {
  // Handle toast click events using event delegation
  document.addEventListener("click", (event) => {
    const element = event.target as HTMLElement;
    // Find closest toast element
    const toast_element = element.closest('[role="status"]');

    if (toast_element) {
      // Extract toast ID from data attributes or class names
      const id = toast_element.getAttribute("data-id");
      if (id) {
        toast.dismiss(id);
      } else {
        // If no ID found, dismiss all toasts
        toast.dismiss();
      }
    }
  });
}

export default DismissableToaster;
