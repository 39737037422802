import { RootState } from "@/app/lib/store/store";
import { createSelector } from "@reduxjs/toolkit";

const selectCompanyInformationState = (state: RootState) => state.companyInformation;

export const selectCompanyInformation = createSelector(
  selectCompanyInformationState,
  (state) => state.data
);
export const selectCompanyInformationLoading = createSelector(
  selectCompanyInformationState,
  (state) => state.isLoading
);
export const selectCompanyInformationError = createSelector(
  selectCompanyInformationState,
  (state) => state.error
);

export const selectCompanyPrefix = createSelector(
  selectCompanyInformation,
  (companyInformation) => companyInformation?.prefix
);

export const selectCompanyQrCodeEmbeddings = createSelector(
  selectCompanyInformation,
  (companyInformation) => companyInformation?.qrCodeEmbeddings || []
);

export const selectCompanyShortId = createSelector(
  selectCompanyInformation,
  (companyInformation) => companyInformation?.shortId
);
