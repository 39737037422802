"use client";

import { Fragment } from "react";
import {
  Listbox,
  Transition,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/20/solid";
import { UsersCompany } from "../interfaces";

interface CompanySelectorProps {
  companies: UsersCompany[];
  selectedCompany: UsersCompany | null;
  onCompanyChange: (company: UsersCompany) => void;
}

export function CompanySelector({
  companies,
  selectedCompany,
  onCompanyChange,
}: CompanySelectorProps) {
  if (!companies) {
    return (
      <div className="w-full md:w-72">
        <div className="relative mt-1">
          <div className="relative w-full cursor-not-allowed rounded-lg bg-gray-100 py-2 pl-3 pr-10 text-left border text-gray-500">
            Loading companies...
          </div>
        </div>
      </div>
    );
  }

  if (companies.length === 0) {
    return (
      <div className="w-full md:w-72">
        <div className="relative mt-1">
          <div className="relative w-full cursor-not-allowed rounded-lg bg-gray-100 py-2 pl-3 pr-10 text-left border text-gray-500">
            No companies available
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full md:w-72">
      <Listbox value={selectedCompany} onChange={onCompanyChange}>
        <div className="relative mt-1">
          <ListboxButton className="relative w-full cursor-pointer rounded-lg bg-white py-2 pl-3 pr-10 text-left border focus:outline-none focus-visible:border-blue-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-blue-300 sm:text-sm">
            <span className="block truncate">
              {selectedCompany?.companyName ??
                selectedCompany?.companyId ??
                "Select a company"}
            </span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </ListboxButton>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-[100] border border-gray-200">
              {companies.map((company) => (
                <ListboxOption
                  key={company.companyId}
                  className={({ active }) =>
                    `relative cursor-pointer select-none py-2 pl-10 pr-4 ${
                      active ? "bg-blue-100 text-blue-900" : "text-gray-900"
                    } ${!company.isVerified ? "opacity-50 cursor-not-allowed" : ""}`
                  }
                  value={company}
                  disabled={!company.isVerified}
                >
                  {({ selected }) => (
                    <Fragment key={`${company.companyId}-content`}>
                      <div className="flex flex-col">
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {company.companyName ?? company.companyId}
                        </span>
                        {!company.isVerified && (
                          <span className="text-xs text-yellow-600 flex items-center gap-1 mt-1">
                            <ExclamationTriangleIcon className="h-3 w-3" />
                            Not verified by company admin
                          </span>
                        )}
                      </div>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </Fragment>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}
