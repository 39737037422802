"use client";

import { useState, useRef, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { useAuth } from "../context/auth-context";
import { useAppSelector } from "../lib/store/hooks";
import { selectSelectedCompany, selectOperatingSystem } from "../lib/store/slices/appSelectors";
import { db, storage } from "../lib/firebase/firebase";
import { doc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";
import { UserFeedback } from "../lib/interfaces";
import { DateService } from "../lib/services/date.service";
import { v4 as uuidv4 } from "uuid";
import { PATHS } from "../lib/firebase/constants";

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function FeedbackModal({ isOpen, onClose }: FeedbackModalProps) {
  const { user } = useAuth();
  const selectedCompany = useAppSelector(selectSelectedCompany);
  const operatingSystem = useAppSelector(selectOperatingSystem);
  const [feedback, setFeedback] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const dropZoneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handlePaste = async (e: ClipboardEvent) => {
      const items = e.clipboardData?.items;
      if (!items) return;

      for (const item of Array.from(items)) {
        if (item.type.indexOf("image") !== -1) {
          const file = item.getAsFile();
          if (!file) continue;

          if (files.length >= 10) {
            toast.error("You can only upload up to 10 files");
            return;
          }

          // Create a unique filename for the pasted image
          const timestamp = Date.now();
          const newFile = new File([file], `pasted_image_${timestamp}.png`, {
            type: file.type,
          });

          setFiles((prev) => [...prev, newFile]);
          toast.success("Image added from clipboard");
          break; // Only handle the first image
        }
      }
    };

    if (isOpen) {
      window.addEventListener("paste", handlePaste);
      return () => window.removeEventListener("paste", handlePaste);
    }
  }, [isOpen, files]);

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = Array.from(e.target.files || []);
    const validFiles = selectedFiles.filter((file) => {
      const isValid =
        file.type.startsWith("image/") || file.type === "application/pdf";
      if (!isValid) {
        toast.error(
          `${file.name} is not a valid file type. Only images and PDFs are allowed.`
        );
      }
      return isValid;
    });

    if (validFiles.length + files.length > 10) {
      toast.error("You can only upload up to 10 files");
      return;
    }

    setFiles((prev) => [...prev, ...validFiles]);
  };

  const removeFile = (index: number) => {
    setFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !selectedCompany) return;

    try {
      setIsSubmitting(true);
      const uuid = uuidv4();

      // Upload files first
      const uploadedFiles: string[] = await Promise.all(
        files.map(async (file) => {
          const storageRef = ref(
            storage,
            `feedback/${uuid}/${Date.now()}_${file.name}`
          );
          await uploadBytes(storageRef, file);
          return getDownloadURL(storageRef);
        })
      );

      const feedbackData: Omit<UserFeedback, "id"> = {
        userId: user.uid,
        userEmail: user.email || "",
        userName: user.displayName || "",
        userRole: selectedCompany.userRole as any,
        companyId: selectedCompany.companyId,
        companyName: selectedCompany.companyName,
        feedback,
        attachedFiles: uploadedFiles,
        createdAt: DateService.now(),
        lastUpdatedAt: DateService.now(),
        status: "added",
        to: "danyil.fedorov@gmail.com",
        message: {
          subject: `New Feedback from ${selectedCompany.companyName}`,
          html: `
          <h2>New Feedback Submitted</h2>
          <p><strong>User:</strong> ${user.displayName || user.email}</p>
          <p><strong>Email:</strong> ${user.email}</p>
          <p><strong>Role:</strong> ${selectedCompany.userRole}</p>
          <p><strong>Company:</strong> ${selectedCompany.companyName}</p>
          <p><strong>Company ID:</strong> ${selectedCompany.companyId}</p>
          <p><strong>Feedback:</strong> ${feedback}</p>
          <p><strong>Attached Files:</strong> ${uploadedFiles.join(", ")}</p>
          `,
        },
      };

      const feedbackRef = doc(db, PATHS.documents.directUserFeedback(uuid));
      await setDoc(feedbackRef, feedbackData);

      toast.success("Feedback submitted successfully. Thank you!");
      setFeedback("");
      setFiles([]);
      onClose();
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Failed to submit feedback");
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDragEnter = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    // Only set dragging to false if we're leaving the dropzone
    const rect = dropZoneRef.current?.getBoundingClientRect();
    if (rect) {
      const { clientX, clientY } = e;
      if (
        clientX <= rect.left ||
        clientX >= rect.right ||
        clientY <= rect.top ||
        clientY >= rect.bottom
      ) {
        setIsDragging(false);
      }
    }
  };

  const handleDragOver = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e: React.DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const droppedFiles = Array.from(e.dataTransfer.files);
    const validFiles = droppedFiles.filter((file) => {
      const isValid =
        file.type.startsWith("image/") || file.type === "application/pdf";
      if (!isValid) {
        toast.error(
          `${file.name} is not a valid file type. Only images and PDFs are allowed.`
        );
      }
      return isValid;
    });

    if (validFiles.length + files.length > 10) {
      toast.error("You can only upload up to 10 files");
      return;
    }

    setFiles((prev) => [...prev, ...validFiles]);
    if (validFiles.length > 0) {
      toast.success(
        `${validFiles.length} file${validFiles.length === 1 ? "" : "s"} added`
      );
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel
          ref={dropZoneRef}
          className={`mx-auto max-w-2xl w-full bg-white rounded-xl shadow-lg relative ${isDragging ? "ring-2 ring-blue-500" : ""
            }`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {isDragging && (
            <div className="absolute inset-0 bg-blue-50 bg-opacity-50 rounded-xl flex items-center justify-center">
              <div className="text-lg text-blue-600 font-medium">
                Drop files here
              </div>
            </div>
          )}
          <div className="p-6">
            <Dialog.Title className="text-lg font-medium text-gray-900 mb-4">
              Submit Feedback or Suggestion
              <span className="text-sm font-normal text-gray-500 ml-2">
                (Next time to open this, press{" "}
                {operatingSystem === "mac" ? "⌘" : "Ctrl"} + K + F)
              </span>
            </Dialog.Title>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <textarea
                  ref={textareaRef}
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  placeholder="Your feedback or suggestion... You can also paste or drop files here"
                  required
                  rows={4}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
              </div>

              <div>
                <p className="text-sm text-gray-500 mb-2">
                  or write directly to{" "}
                  <a
                    href="mailto:danyil.fedorov@gmail.com"
                    className="text-blue-600 hover:text-blue-800"
                  >
                    danyil.fedorov@gmail.com
                  </a>
                </p>
              </div>

              <div>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  accept="image/*,.pdf"
                  className="hidden"
                  multiple
                />
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                  className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Attach Files (Images or PDFs)
                </button>
                <p className="text-xs text-gray-500 mt-1">
                  Up to 10 files allowed
                </p>
              </div>

              {files.length > 0 && (
                <div className="space-y-2">
                  {files.map((file, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between bg-gray-50 p-2 rounded"
                    >
                      <span className="text-sm truncate">{file.name}</span>
                      <button
                        type="button"
                        onClick={() => removeFile(index)}
                        className="text-red-600 hover:text-red-800 text-sm"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                </div>
              )}

              <div className="flex justify-end space-x-4 pt-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting || !feedback.trim()}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  {isSubmitting ? "Submitting..." : "Submit Feedback"}
                </button>
              </div>
            </form>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
